/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/materialIconsFonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.woff") format("woff"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.ttf") format("truetype");
}

.app-receiver{


  .menu-wrapper .p-scrollpanel-content,
  .menu-wrapper .p-scrollpanel-wrapper {
    overflow: visible;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flex-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .align-baseline {
    align-items: baseline;
  }

  .bold {
    font-weight: 500;
  }

  .blank-field {
    background-image: none !important;
    background-color: transparent !important;
  }

  .p-float-label>label {
    top: 12px;
    font-size: 12px;
    white-space: pre-wrap;
  }

  .plausi-row {
    height: fit-content !important;
  }

  .plausi-error-field {
    color: red;
    font-size: smaller;
    word-break: break-word;
  }

  .plausi-error-icon {
    color: red;
    height: 10px;
    width: 10px;
    font-size: 10px;
  }

  .cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90% !important;
    white-space: nowrap !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-40 {
    width: 40% !important;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .scrollable-menu {
    max-height: 480px !important;
  }

  .scrollable {
    max-height: 300px;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  div.p-panel.p-component {
    height: 100%;
  }

  .h-300 {
    max-height: 300px;
  }

  .new-btn-margin {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .btn-streched {
    padding: 0.8rem 3rem 0.8rem 5rem;
    width: 13rem;
    text-align: center;
    color: whitesmoke;
  }

  .div-card {
    align-items: center;
    height: 36px;
    border: 1px solid lightgray;
    border-top: 0;
    padding: 15px;
    box-shadow: 0px 2px 2px lightgray;
    margin-bottom: 5px;
  }

  .h-80 {
    max-height: 80px;
  }

  .h-250 {
    max-height: 250px;
  }

  .h-220 {
    height: 220px;
  }

  .add-btn {
    justify-content: end !important;
  }

  .p-2 {
    padding: 2.5% !important;
  }

  .pr-30 {
    padding-right: 30px;
  }

  .focused {
    border-top: 1px solid #2196f3 !important;
    border-bottom: 1px solid #2196f3 !important;
    border-right: 1px solid #2196f3 !important;
  }

  div.focus:focus-within {
    border-right: 1px solid #2196f3 !important;
    border-bottom: 1px solid #2196f3 !important;
    border-top: 1px solid #2196f3 !important;
  }

  .light-gray {
    background-color: lightgray;
    
    &:hover,
    &:focus,
    &:active {
      background-color: gray !important;
    }
  }

  .p-input {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom,
        rgba(var(--primary-color), 1),
        rgba(var(--primary-color), 1)),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
  }

  .p-dropdown.p-component {
    width: 100% !important;
  }

  .calendar-full-width {
    .p-calendar {
      width: 100%;
      height: 26px;
      margin-bottom: 8px;
    }
  }

  .calendar-full-width-no-height {
    .p-calendar {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .custom-tab-view {
    .p-tabview {
      margin-top: 10.5px;
    }
  }

  .event-overview-panel {
    height: 100%;

    .p-panel {
      height: 100%;
    }
  }

  .red {
    color: #db0202 !important;
  }

  .bg-red {
    background-color: #db0202;
    color: white;
  }

  .divIconWidth {
    width: 25px;
    height: 25px;
  }

  .statusCircle {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }

  .statusCircleUn {
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }

  .blue {
    color: dodgerblue;
  }

  .bg-blue {
    background-color: dodgerblue;
    color: white;
  }

  .green {
    color: #58a158;
  }

  .bg-green {
    background-color: #58a158;
    color: white;
  }

  .bg-gray {
    background-color: lightgray;
    color: black;
  }

  .yellow {
    color: #ffce00;
  }

  .bg-yellow {
    background-color: #ffce00;
    color: white;
  }

  .lightgray {
    color: lightgray;
  }

  .gray {
    color: gray;
  }

  .bg-lightgray {
    background-color: lightgray;
    color: white;
  }

  .border-green {
    border-left: 3px solid #58a158 !important;
  }

  .border-red {
    border-left: 3px solid #db0202 !important;
  }

  .invalid {
    border-bottom: darkred solid 2px;
  }

  input:focus {
    outline: none;
    border-bottom: 2px solid #2196f3;
  }

  input.p-input.invalid:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid darkred;
  }

  //Navbar Toolbar

  .top-6 {
    top: 6.5rem !important;
  }

  div.p-accordion.p-component {
    min-width: fit-content !important;
  }

  p-dropdown {
    width: 100%;

    .p-dropdown.p-component {
      width: 100% !important;
    }
  }

  p-dropdown.pDropdown div.p-dropdown.p-component {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, rgba(var(--primary-color), 1), rgba(var(--primary-color), 1)), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    width: 100%;
  }

  p-dropdown.pDropdown div.p-dropdown.p-component.p-focus {
    box-shadow: none;
  }

  .p-input {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, rgba(var(--primary-color), 1), rgba(var(--primary-color), 1)), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    width: 100%;
  }

  input.p-input.invalid:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid darkred;
    box-shadow: none;
  }

  input.p-input:focus {
    outline: none !important;
    border: none !important;
    border-bottom: 2px solid #2196F3 !important;
    box-shadow: none !important;
  }

  .flex-col-space-between {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
  }

  .h-100 {
    height: 100%;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .w-60 {
    width: 60%;
  }

  .p-dropdown-item.p-ripple {
    white-space: normal;
  }

  div.p-progress-spinner {
    height: 20px;
  }

  .spinner-streched {
    padding: 0.8rem 3rem;
    width: 13rem;
  }

  .custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-color 1.5s ease-in-out infinite
  }

  @keyframes custom-progress-spinner-color {

    100%,
    0% {
      stroke: white;
    }
  }

  .p-selectbutton .p-button.p-highlight,
  .p-selectbutton .p-button.p-highlight:hover,
  .p-selectbutton .p-button.p-highlight:focus {
    background: dodgerblue;
    color: white;
  }


  span.p-inputnumber.p-component {
    width: 100%;
  }

  .p-paginator-bottom p-dropdown{
    width: 100px !important;
  }

  .hidden {
    display: none;
  }
}