/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/materialIconsFonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.woff") format("woff"),
    url("./assets/materialIconsFonts/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

// Menu & sidebar
.menu-wrapper .p-scrollpanel-content,
.menu-wrapper .p-scrollpanel-wrapper {
  overflow: visible;
}

//#app-logo {
//  filter: drop-shadow(0px 0px 1px #727272);
//  -webkit-filter: drop-shadow(0px 0px 1px #727272);
//}

#app-logo:hover,
#app-logo:focus {
  filter: drop-shadow(1px 1px 2px rgba(173, 173, 173, 0.664));
  -webkit-filter: drop-shadow(1px 1px 2px rgba(173, 173, 173, 0.664));
}

//** Overview tables
.align-column-center {
  text-align: center !important;
}


//Action Button - Access Code
.statusAC-validate {
  background-color: #2196f3;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white;
}
.statusAC-send {
  background-color: #2196f3;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white;
}
.statusAC-waiting {
  background-color: #fbc02d !important;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white !important;
}
.statusAC-error {
  background-color: #d32f2f;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white;
}
.statusAC-internal {
  background-color: #2196f3;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white;
}
.statusAC-confirm {
  background-color: #689f38;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  z-index: 10;
  color: white;
}

//

.statusAC-validate:hover {
  background-color: #2196f3 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}
.statusAC-send:hover {
  background-color: #2196f3 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}
.statusAC-waiting:hover {
  background-color: #f6be30 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}
.statusAC-error:hover {
  background-color: #d32f2f !important;
  color: white !important;
}
.statusAC-internal:hover {
  background-color: #2196f3 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}
.statusAC-confirm:hover {
  background-color: #689f38 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}

.statusAC-DefaultCreate {
  background-color: #2196f3 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}
.statusAC-DefaultUpdate {
  background-color: #2196f3 !important;
  text-shadow: 0 0 2px black;
  color: white !important;
}

#accessCodesList .p-panel {
  //accessCodesList-content
  width: 100%;
  display: flow-root;
  // height: 42em;
}

div#addAccessCodeBTN {
  //BTN add accessCode
  width: 22px;
  height: 22px;
  float: right;
  color: var(--primary-menu-text-color);
  margin: 16px;
  padding: 4px;
}

div#addAccessCodeBTN:hover {
  color: whitesmoke;
  cursor: pointer;
}

.btn-streched {
  padding: 0.8rem 3rem 0.8rem 5rem;
  width: 13rem;
  text-align: center;
  color: whitesmoke;
}


.pointer {
  cursor: pointer;
}

.w-50 {
  width: 50% !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px;
}


.ml-10 {
  margin-left: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.p-float-label>label {
  top: 12px !important;
  font-size: 12px;
  white-space: pre-wrap;
}

.plausi-error-field {
  color: red;
  font-size: smaller;
  word-break: break-word;
}

.plausi-error-label {
  color: red;
}

.plausi-error-icon {
  color: red;
  height: 10px;
  width: 10px;
  font-size: 10px;
}

.div-card {
  align-items: center;
  height: 36px;
  border: 1px solid lightgray;
  border-top: 0;
  padding: 15px;
  box-shadow: 0px 2px 2px lightgray;
  margin-bottom: 5px;
}

.ml-100 {
  margin-left: -100px;
}

.add-btn {
  justify-content: flex-end !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.bold {
  font-weight: 500;
}

.gray-div {
  background-color: rgb(244, 242, 242);
  border-bottom: 1px gray solid;
  height: 50px;
}

.p-dropdown.p-component {
  width: 100% !important;
}

.p-panel-title {
  font-size: 14px !important;
}

.blank-field {
  background-image: none !important;
  background-color: transparent !important;
  border-width: 0 !important;
  padding-left: 0;
  padding-right: 0;
}

.w-dropdown {
  width: 82%;
}


.p-input {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, rgba(var(--primary-color), 1), rgba(var(--primary-color), 1)), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
  width: 100%;
}

p-dropdown.pDropdown div.p-dropdown.p-component {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #f5f5f5 no-repeat;
  background-image: linear-gradient(to bottom, rgba(var(--primary-color), 1), rgba(var(--primary-color), 1)), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
  width: 100%;
}

p-dropdown.pDropdown div.p-dropdown.p-component.p-focus {
  box-shadow: none;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}

.h-30 {
  height: 30px;
}

.p-20 {
  padding: 20px 0px;
}

.plausi-errors-panel .p-component {
  height: unset !important;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 220px;
  white-space: nowrap !important;
}

.h-200 {
  max-height: 200px;
}

.light-gray {
  background-color: lightgray;

  &:hover,
  &:focus,
  &:active {
    background-color: gray !important;
  }
}


.mb-20 {
  margin-bottom: 20px;
}

.red {
  color: #db0202 !important;
}

.bg-red {
  background-color: #db0202;
  color: white;
}

.bg-red:hover {
  background-color: #db0202 !important;
  color: white;
}

.blue {
  color: dodgerblue;
}

.bg-blue {
  background-color: dodgerblue;
  color: white;
}

.bg-blue:hover {
  background-color: dodgerblue !important;
  color: white;
}

.green {
  color: #58a158;
}

.bg-green {
  background-color: #58a158;
  color: white;
}

.bg-green:hover {
  background-color: #58a158 !important;
  color: white;
}

.yellow {
  color: #ffce00;
}

.bg-yellow {
  background-color: #ffce00;
  color: white;
}

.bg-yellow:hover {
  background-color: #ffce00 !important;
  color: white;
}

.lightgray {
  color: lightgray;
}

.gray {
  color: gray;
}

.bg-gray {
  background-color: gray;
}

.bg-lightgray {
  background-color: lightgray;
  color: white;
}

.bg-lightgray:hover {
  background-color: lightgray !important;
  color: white;
}

.transparent {
  color: transparent;
}

.hidden {
  display: none;
}

.new-btn-margin {
  margin-top: -10px;
  margin-bottom: 10px;
}

.h-85 {
  max-height: 85px;
}

.advanced-filter {

  .p-multiselect {
    width: 100%;
    height: 50px;
    background: none !important;

  }

  .p-multiselect:hover {
    background: none !important;
  }

  .p-multiselect-label-empty {
    visibility: visible !important;
  }

}

.filter-input.text-input {
  background-image: linear-gradient(to bottom, rgba(var(--primary-color), 1), rgba(var(--primary-color), 1)), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38)) !important;
}

.filter-input {
  .p-calendar {
    background-color: transparent;

  }
}

.filter-input:hover,
.filter-input:active,
.filter-input:focus {
  background-image: none !important;
}

.border-green {
  border-left: 3px solid #58a158 !important;
}

.border-red {
  border-left: 3px solid #db0202 !important;
}

.border-yellow {
  border-left: 3px solid yellow !important;
}

.invalid {
  border-bottom: darkred solid 2px;
}

input.p-input:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid #2196F3;
}

input.p-input.invalid:focus {
  outline: none;
  border: none;
  border-bottom: 2px solid darkred;
}

.top-6 {
  top: 6.5rem !important;
}

div.p-accordion.p-component {
  min-width: fit-content !important;
}

.h-40 {
  height: 40px;
}

.p-dropdown-item.p-ripple {
  white-space: normal;
}

.pDropdown div.p-dropdown-panel.p-component {
  width: inherit;
}

input.p-dropdown-filter.p-inputtext.p-component:focus,
input.p-dropdown-filter.p-inputtext.p-component:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.w-18 {
  width: 18vw !important;
}

.w-16 {
  width: 16vw !important;
}

.w-15 {
  width: 15vw !important;
}

.w-12vw {
  width: 12vw !important;
}

.w-10 {
  width: 10vw !important;
}

.w-8 {
  width: 8vw !important;
}

.w-7 {
  width: 7vw !important;
}

.w-6 {
  width: 6vw !important;
}

.w-60 {
  width: 60%;
}

.h-100 {
  height: 100%;
}

.status-chip {
  display: inline-block;
  padding: 0 15px;
  width: 8rem;
  height: 2rem;
  font-size: .8rem;
  font-weight: 700;
  line-height: 2rem;
  border-radius: 25px;
  text-align: center;
}

.status-chip:hover {
  z-index: 999;
}

.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.mr-50 {
  margin-right: 50px;
}

.w-20 {
  width: 20%;
}

div.p-progress-spinner {
  height: 20px;
}

.spinner-streched {
  padding: 0.55rem 3rem;
  width: 13rem;
}

.streched-btn {
  width: 13rem;
  color: whitesmoke;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.8rem 3rem 0.8rem 5rem;
  text-align: center;
}

.streched-btn:hover {
  color: white;
}

.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-color 1.5s ease-in-out infinite
}

@keyframes custom-progress-spinner-color {

  100%,
  0% {
    stroke: white;
  }
}

span.p-inputnumber.p-component {
  width: 100%;
}

.status-max-width {
  max-width: 900px;
}

.min-w-30 {
  width: 30% !important;
  min-width: 30% !important;
}

.p-dropdown {
  min-height: 39.4px;
}

.td-vertreter {
  min-height: 100%;
}

// STICKY HEADER
.sticky-header {
  background-color: var(--menu-bg-color);
  z-index: 2;
  position: fixed;
  top: 6.5rem;
  left: 0;
  right: 0;
}

.card-sticky-header {
  margin: 0;
  padding: 2rem 2rem 1rem 2rem;
}

.controlled-tabs {
  display: block;
  padding: .5em 0 1em 0;
}

.w-fit-body {
  margin-left: 44px;
  margin-right: 44px;
}

.topmargin-transit-declaration {
  margin-top: 4.5rem;
}

.topmargin-transit-receiver {
  margin-top: 3rem;
  padding-top: 2rem;
}

.topmargin-unload-remark {
  margin-top: 6rem;
}

.topmargin-status-request {
  margin-top: 3rem;
  padding-top: 2rem;
}

.topmargin-grn {
  margin-top: 4rem;
  padding-top: 2rem;
}

.topmargin-storage-location {
  margin-top: 3rem;
  padding-top: 3rem;
}

.tab1 {
  margin-left: 0;
}

.tab2 {
  margin-left: .9rem;
}

.tab3 {
  margin-left: .9rem;
}

.active-controlled-tab {
  color: rgba(var(--primary-color), 1);
}
// STICKY HEADER END
